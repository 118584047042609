var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "not-found d-flex flex-column justify-center align-center h-full text-center px-10",
    },
    [
      _c("v-img", {
        attrs: {
          src: require("@/assets/images/404.png"),
          "max-height": "400",
          contain: "",
        },
      }),
      _c("div", { staticClass: "not-found--title mt-8 mb-2" }, [
        _vm._v(_vm._s(_vm.$t("notFound.title"))),
      ]),
      _c("div", { staticClass: "not-found--subtitle mb-10" }, [
        _vm._v(_vm._s(_vm.$t("notFound.subTitle"))),
      ]),
      _c("v-btn", { attrs: { to: "/", large: "", color: "secondary" } }, [
        _vm._v(_vm._s(_vm.$t("goToHome"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }